<template>
  <div>
    <div class="card p-2">
      <div>
        <h3 class="mb-0">
          เพิ่มข่าวสารประชาสัมพันธ์
        </h3>
      </div>

      <div class="row mt-1">
        <div class="col-md-4">
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <label for="asd">หัวข้อข่าว</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Topic"
                      rules="required"
                    >
                      <b-form-input
                        v-model="data.title"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label for="asd">เนื้อหาย่อ</label>
                    <validation-provider
                      #default="{ errors }"
                      name="News Short"
                      rules="required"
                    >
                      <b-form-textarea
                        v-model="data.detail"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <label for="asd">ลิ้งข่าว</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Topic"
                      rules="required"
                    >
                      <b-form-input
                        v-model="data.ref_url"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <label for="asd">ลิ้งรูป</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Topic"
                      rules="required"
                    >
                      <b-form-input
                        v-model="data.img_url"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <img
                    v-if="data.img_url"
                    :src="data.img_url"
                    width="300"
                    height="200"
                    frameborder="0"
                    allowfullscreen
                  >
                </b-col>

                <!-- <b-col md="12">
                  <b-form-group>
                    <label for="asd">รูปปกข่าว</label>

                    <b-form-file
                      v-model="file"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      @input="uploadImg(file)"
                    />

                  </b-form-group>
                  <img
                    v-if="data.img"
                    :src="data.img"
                    width="300"
                    height="200"
                    frameborder="0"
                    allowfullscreen
                  >
                </b-col> -->
              </b-row>
            </b-form>
          </validation-observer>
        </div>

        <div class="col-md-8" />

        <div class="col-md-12">
          <!-- <VueEditor /> -->
          <b-button
            variant="primary"
            type="submit"
            class="mt-1"
            @click.prevent="validationForm"
          >
            บันทึกข้อมูล
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  // BFormFile,
} from 'bootstrap-vue'
import { required } from '@validations'
// eslint-disable-next-line import/extensions
// import { VueEditor } from 'vue2-editor/dist/vue2-editor.core.js'

export default {
  name: 'CreateNews',
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    // VueEditor,
    // BFormFile,
  },
  data() {
    return {
      emailValue: '',
      name: '',
      required,
      data: '',
      img: '',
      img_url: '',
    }
  },
  mounted() {
    this.GetDetail()
  },
  methods: {
    uploadImg(file) {
      // แปลงไฟล์รูปเป็น base64
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.img = reader.result
        // บีบอัดรูปให้เล็กลง
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const image = new Image()
        image.src = this.img
        image.onload = () => {
          canvas.width = image.width
          canvas.height = image.height
          ctx.drawImage(image, 0, 0, image.width, image.height)
          const dataUrl = canvas.toDataURL('image/jpeg')
          this.data.img = dataUrl
        }
      }
    },
    async GetDetail() {
      await this.$http
        .get(`/news/GetDetail/${this.$route.params.id}`)
        .then(response => {
          this.data = response.data
        })
        .catch(error => console.log(error))
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // บันทึกข้อมูล
          this.$http
            .post(`/news/update/${this.$route.params.id}`, this.data)
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.Success('แก้ไขข้อมูลสำเร็จ')
              this.$router.push('/News')
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.SwalError('อาจเกิดจากรูปมีขนาดใหญ่เกินไป')
            })
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

  <style lang="css">
  @import "~vue2-editor/dist/vue2-editor.css";

  /* Import the Quill styles you want */
  @import "~quill/dist/quill.core.css";
  @import "~quill/dist/quill.bubble.css";
  @import "~quill/dist/quill.snow.css";
  </style>
